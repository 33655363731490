import { OnboardingDTO, OnboardingDTOOrganisationType } from "src/api/UIClient"
import StepAddDevice from "./wizard/StepAddDevice";
import StepComplete from "./wizard/StepComplete";
import StepCreateEmployee from "./wizard/StepCreateEmployee";
import StepCreateVehicle from "./wizard/StepCreateVehicle";
import StepEnterPTA from "./wizard/StepEnterPTA";
import StepLoadStore from "./wizard/StepLoadStore";
import StepSelectPTO from "./wizard/StepSelectPTO";
import StepType from "./wizard/StepType";

export type StepKey = 'organisation-type' | 'enter-pta' | 'store-setup' | 'select-pto' | 'create-employee' | 'create-vehicle' | 'add-device' | 'completed'

export interface BaseStepProps {
  onChangeStep: (step: StepKey, submitData: Partial<OnboardingDTO> | null, setData?: Partial<OnboardingDTO>) => void
  onSubmitData: (data: Partial<OnboardingDTO>) => void
  onSkip: () => void

  data: OnboardingDTO
}

export type StepDefinition = {
  icon: string
  title: string
  subtitle: string
  order: number

  requirementsMet: (data: OnboardingDTO) => boolean
  Component: (props: BaseStepProps) => React.ReactElement
}

export const ONBOARDING_STEPS: Record<StepKey, StepDefinition> = {
  "organisation-type": {
    icon: "tabler:building",
    title: "stepType.title",
    subtitle: "stepType.subTitle",
    order: 0,
    Component: props => <StepType {...props} />,
    requirementsMet: data => !!data.organisationType,
  },
  "enter-pta": {
    icon: "tabler:building-bank",
    title: "enterPta.title",
    subtitle: "enterPta.subTitle",
    order: 1,
    Component: props => <StepEnterPTA {...props} />,
    requirementsMet: data => !!data.authorityName,
  },
  "store-setup": {
    icon: "tabler:database",
    title: "loadStore.title",
    subtitle: "loadStore.subTitle",
    order: 2,
    Component: props => <StepLoadStore {...props} />,
    requirementsMet: data => data.dataSourceLoaded === true,
  },
  "select-pto": {
    icon: "tabler:flag",
    title: "selectPto.title",
    subtitle: "selectPto.subTitle",
    order: 3,
    Component: props => <StepSelectPTO {...props} />,
    requirementsMet: data => data.organisationType !== OnboardingDTOOrganisationType.PTO || !!data.authorityId,
  },
  "create-employee": {
    icon: "tabler:user-edit",
    title: "createEmployee.title",
    subtitle: "createEmployee.subTitle",
    order: 4,
    Component: props => <StepCreateEmployee {...props} />,
    requirementsMet: data => !!data.employeeName,
  },
  "create-vehicle": {
    icon: "tabler:bus",
    title: "createVehicle.title",
    subtitle: "createVehicle.subTitle",
    order: 5,
    Component: props => <StepCreateVehicle {...props} />,
    requirementsMet: data => !!data.vehicleRegistrationNumber,
  },
  "add-device": {
    icon: "tabler:device-tablet-plus",
    title: "addDevice.title",
    subtitle: "addDevice.subTitle",
    order: 6,
    Component: props => <StepAddDevice {...props} />,
    requirementsMet: data => data.deviceConnected === true,
  },
  "completed": {
    icon: "tabler:check",
    title: "completed.title",
    subtitle: "completed.subTitle",
    order: 7,
    Component: props => <StepComplete {...props} />,
    requirementsMet: data => false,
  }
};
