import { GridColumns } from "@mui/x-data-grid";
import { useState } from "react";
import { ListCardPaginationProvider } from "./ListCardPaginationContext";
import ViewModeCard from "./ViewModeCard";
import ViewModeTable from "./ViewModeTable";
import { CircularProgress } from "@mui/material";

export type ViewMode = "list" | "card";

export type ListCardViewProps = {
  loading?: boolean
  data: any[];
  columns: GridColumns<any>;
  renderCard: (data: any) => React.ReactNode;
};

const ListCardView = (props: ListCardViewProps) => {
  const { loading, data, columns, renderCard } = props;

  const [viewMode, setViewMode] = useState<ViewMode>("list");

  const toggleViewMode = () =>
    setViewMode((it) => (it === "list" ? "card" : "list"));

  return (
    <ListCardPaginationProvider>
      {loading === true
          ? <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress disableShrink />
          </div>
          : viewMode === "list" ? (
            <ViewModeTable
              data={data}
              toggleViewMode={toggleViewMode}
              columns={columns}
            />
          ) : (
            <ViewModeCard data={data} toggleViewMode={toggleViewMode} renderCard={renderCard} />
          )}
    </ListCardPaginationProvider>
  );
};

export default ListCardView;
