import { Icon } from "@iconify/react";
import {
  Avatar,
  Collapse,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "src/api";
import {
  GetLineResponse,
  GetRouteResponse,
  GetStopPointResponse,
} from "src/api/UIClient";
import { useToast } from "src/context/ToastContext";
import { callApi } from "src/hooks/useApi";

type Mode = "lines" | "stops";

export type LinesMenuProps = {
  onRouteSelect: (routeId: string | null) => void;

  onStopsMode: (stops: GetStopPointResponse[]) => void;
  onStopSelected: (index: number) => void;
};

const LinesMenu = (props: LinesMenuProps) => {
  const { onRouteSelect, onStopsMode, onStopSelected } = props;

  const { t } = useTranslation("network");
  const toast = useToast();

  const [mode, setMode] = useState<Mode>("lines");

  const [stops, setStops] = useState<GetStopPointResponse[] | null>(null);

  const [lines, setLines] = useState<GetLineResponse[] | null>(null);
  const [selectedLine, setSelectedLine] = useState<string | null>(null);

  const [routes, setRoutes] = useState<GetRouteResponse[]>([]);
  const [selectedRoute, setSelectedRoute] = useState<number>(0);

  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const fetchLines = async () => {
      const { data, error } = await callApi<GetLineResponse[]>(() =>
        API.networkGetLines()
      );
      if (error) {
        console.error(error);
        toast("error", t("common:oops"));
      } else {
        setLines(data);

        if (data && data.length > 0) {
          setSelectedLine(data[0].id!);
          fetchRoutes(data[0].id!);
        }
      }
    };

    const fetchStops = async () => {
      const { data, error } = await callApi<GetStopPointResponse[]>(() =>
        API.networkGetStopPoints()
      );
      if (error) {
        console.error(error);
        toast("error", t("common:oops"));
      } else {
        setStops(data!);
      }
    };

    fetchLines();
    fetchStops();
  }, []);

  const fetchRoutes = async (lineId: string, selectRouteIndex?: number) => {
    setRoutes([]);

    const { data, error } = await callApi<GetRouteResponse[]>(() =>
      API.networkGetLineRoutes(lineId)
    );

    if (error) {
      console.error(error);
      toast("error", t("common:oops"));
      return;
    }

    setRoutes(data!);

    if (data && data.length > 0 && selectRouteIndex !== undefined) {
      onRouteSelect(data[selectRouteIndex].id!);
    } else {
      onRouteSelect(null);
    }
  };

  const onListSelect = async (line: GetLineResponse) => {
    setSelectedLine(line.id!);
    setSelectedRoute(0);
    fetchRoutes(line.id!, 0);
  };

  const onRouteSelectList = async (index: number) => {
    setSelectedRoute(index);

    onRouteSelect(routes[index].id!);
  };

  const onSetMode = async (mode: Mode) => {
    setMode(mode);
    setSearch("");

    if (mode === "lines") {
      if (routes && routes.length > 0 && selectedRoute !== undefined) {
        onRouteSelect(routes[selectedRoute].id!);
      } else {
        onRouteSelect(null);
      }
    } else if (mode === "stops") {
      onStopsMode(stops ?? []);
    }
  };

  useEffect(() => {
    if (lines && selectedLine) {
      fetchRoutes(selectedLine);
    }
  }, [lines]);

  const filteredLines =
    search.length > 0
      ? lines?.filter((it) => it.shortName!.includes(search))
      : lines;

  const filteredStopPoints =
    search.length > 0
      ? stops?.filter((it) => it.name!.includes(search))
      : stops;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flex: "0", textAlign: "center" }}>
        <ToggleButtonGroup
          sx={{ flex: "0", margin: 1 }}
          color="primary"
          value={mode}
          exclusive
          onChange={(_, data) => onSetMode(data as Mode)}
        >
          <ToggleButton value="lines">{t("mode.lines")}</ToggleButton>
          <ToggleButton value="stops">{t("mode.stops")}</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <OutlinedInput
        sx={{ flex: "0", margin: 1 }}
        type="text"
        startAdornment={
          <InputAdornment position="start">
            <Icon icon="tabler:search" />
          </InputAdornment>
        }
        endAdornment={
          search.length > 0 ? (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearch("")} edge="end">
                <Icon icon="tabler:x" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        placeholder={t("search")}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {mode === "lines" && (
        <>
          <List sx={{ flex: "1", overflowY: "auto" }}>
            {filteredLines &&
              filteredLines.map((it, index) => (
                <>
                  <ListItemButton
                    selected={selectedLine === it.id}
                    onClick={() => onListSelect(it)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Icon icon="tabler:bus" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={it.shortName} secondary={it.name} />
                  </ListItemButton>
                  {selectedLine === it.id && (
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <List
                        sx={{ marginLeft: "2rem" }}
                        component="div"
                        disablePadding
                      >
                        {routes != null ? (
                          routes.length == 0 ? (
                            <span>{t("noRoutes")}</span>
                          ) : (
                            routes.map((it, routeIndex) => (
                              <ListItemButton
                                sx={{ pl: 4 }}
                                selected={selectedRoute === routeIndex}
                                onClick={() => onRouteSelectList(routeIndex)}
                              >
                                <ListItemAvatar>
                                  <Avatar>
                                    <Icon icon="tabler:arrows-diff" />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={it.name}
                                  secondary={
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon icon="tabler:clock" />
                                      <span style={{ marginRight: "8px" }}>
                                        {it.departureTime}
                                      </span>

                                      <Icon icon="tabler:route-x" />
                                      <span>{it.journeyDuration}</span>
                                    </div>
                                  }
                                />
                              </ListItemButton>
                            ))
                          )
                        ) : null}
                      </List>
                    </Collapse>
                  )}
                </>
              ))}
          </List>
        </>
      )}

      {mode === "stops" && (
        <List sx={{ flex: "1", overflowY: "auto" }}>
          {filteredStopPoints &&
            filteredStopPoints.map((it, index) => (
              <ListItemButton onClick={() => onStopSelected(index)}>
                <ListItemAvatar>
                  <Avatar>
                    <Icon icon="tabler:bus-stop" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={it.name} />
              </ListItemButton>
            ))}
        </List>
      )}
    </div>
  );
};

export default LinesMenu;
