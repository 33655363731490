import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import UserLayout from "src/layouts/UserLayout";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { GetVehicleResponse } from "src/api/UIClient";
import { callApi } from "src/hooks/useApi";
import { API } from "src/api";
import { Button, IconButton, styled } from "@mui/material";
import Icon from "src/@core/components/icon";
import { useConfirmDialog } from "src/context/ConfirmDialogContext";
import ListCardView from "src/components/list-card-view/ListCardView";
import { useToast } from "src/context/ToastContext";
import VehicleEditDialog, { VehicleEditDialogRef } from "./VehicleEditDialog";
import DeviceQRDialog, { DeviceQRDialogHandle } from "src/components/DeviceQRDialog";

interface CellType {
  row: GetVehicleResponse;
}

const RowOptions = ({ onQR, onEdit, onDelete }: { onQR: () => void, onEdit: () => void, onDelete: () => void }) => {
  return (
    <>
      <IconButton onClick={onQR}>
        <Icon icon="tabler:qrcode" fontSize={24} />
      </IconButton>
      <IconButton onClick={onEdit}>
        <Icon icon="tabler:edit" fontSize={24} />
      </IconButton>
      <IconButton onClick={onDelete}>
        <Icon icon="tabler:trash" fontSize={24} />
      </IconButton>
    </>
  );
};

const VehicleCardWrapper = styled("div")({
  minWidth: "400px",
  padding: "5px",
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: "25%",
});

const Layout = styled("div")({
  border: "1px solid #dbdade",
  borderRadius: "4px",
  padding: "0.5rem",
});

const IconWrapper = styled("div")({
  width: "100%",
  minHeight: "75px",
  borderRadius: "4px",
  background: "#dedede",
  display: "flex",
  flexFlow: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1rem",
});

const VehicleCard = (props: { data: GetVehicleResponse }) => {
  const { data } = props;

  return (
    <VehicleCardWrapper key={data.id}>
      <Layout>
        <IconWrapper>
          <Icon icon="tabler:bus" fontSize="3rem" />
        </IconWrapper>
        {data.registrationNumber}
      </Layout>
    </VehicleCardWrapper>
  );
};

const VehiclesPage = () => {
  const toast = useToast();
  const { t } = useTranslation("vehicles");

  const confirm = useConfirmDialog();

  const vehicleEditDialog = useRef<VehicleEditDialogRef>(null);
  const deviceDialog = useRef<DeviceQRDialogHandle>(null);

  const columns = useMemo(
    () => [
      {
        flex: 0.5,
        field: "registrationNumber",
        minWidth: 80,
        headerName: t("columns.registrationNumber"),
      },
      {
        flex: 0.2,
        field: "registeredOn",
        minWidth: 80,
        headerName: t("columns.registeredOn"),
        renderCell: ({ row }: CellType) => new Date().toLocaleString(),
      },
      {
        flex: 0.2,
        field: "createdBy",
        minWidth: 80,
        headerName: t("columns.createdBy"),
        renderCell: ({ row }: CellType) => "Admin",
      },
      {
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        field: "actions",
        headerName: t("columns.actions"),
        renderCell: ({ row }: CellType) => (
          <RowOptions
            onQR={() => deviceDialog.current?.open(null, row.id!)}
            onEdit={() => vehicleEditDialog.current?.open(row)}
            onDelete={() =>
              confirm({
                title: "Delete the vehicle?",
                content:
                  "Are you sure want to delete the vehicle? This cannot be undone!",
                acceptLabel: "Delete",
                declineLabel: "Cancel",
              })
            }
          />
        ),
      },
    ],
    [t]
  );

  const [vehicles, setVehicles] = useState<GetVehicleResponse[] | null>(null);
  const fetchVehicles = async () => {
    const { data, error } = await callApi(() => API.vehicleGetAll());
    if (error) {
      toast("error", t("common:oops"));
      console.error(error);
      return;
    }

    setVehicles(data!);
  };

  const onAddVehicle = () => vehicleEditDialog.current?.open(null);

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <UserLayout>
      <VehicleEditDialog ref={vehicleEditDialog} refreshList={fetchVehicles} />
      <DeviceQRDialog ref={deviceDialog} />
      <Card>
        <CardHeader
          title={t("title")}
          action={
            <Button variant="contained" sx={{ "& svg": { mr: 2 } }} onClick={onAddVehicle}>
              <Icon fontSize="1.125rem" icon="tabler:plus" />
              {t("addAction")}
            </Button>
          }
        />
        <Box sx={{ height: 500 }}>
          <ListCardView
            loading={vehicles == null}
            data={vehicles ?? []}
            columns={columns}
            renderCard={(data) => <VehicleCard key={data.id} data={data} />}
          />
        </Box>
      </Card>
    </UserLayout>
  );
};

export default VehiclesPage;
