import { useContext } from "react";
import { LIST_CARD_PAGESIZE, ListCardPaginationContext } from "./ListCardPaginationContext";
import { styled } from "@mui/system";
import { CardViewFooter } from "./PaginationFooter";

export type ViewModeCardProps = {
  data: any[];
  toggleViewMode: () => void;
  renderCard: (data: any) => React.ReactNode;
};

const CardWraper = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column"
});

const CardFlexLayout = styled("div")({
  flex: "1",
  display: "flex",
  flexFlow: "row",
  flexWrap: "wrap",
  overflowY: "scroll",
});

const ViewModeCard = (props: ViewModeCardProps) => {
  const { data, toggleViewMode, renderCard } = props;

  const paginationState = useContext(ListCardPaginationContext);

  const pageData = data.slice(paginationState.pageSize * paginationState.page, paginationState.pageSize * (paginationState.page + 1));

  return (
    <CardWraper>
      <CardFlexLayout>
        {(pageData ?? []).map((it) => renderCard(it))}
      </CardFlexLayout>
      <CardViewFooter
        viewMode="card"
        toggleViewMode={toggleViewMode}
        itemsCount={data?.length ?? 0}
        page={paginationState.page}
        rowsPerPageOptions={LIST_CARD_PAGESIZE}
        rowsPerPage={paginationState.pageSize}
        onPageChange={paginationState.setPage}
        onPageSizeChange={paginationState.setPageSize} />
    </CardWraper>
  );
};

export default ViewModeCard;
