import { OnboardingDTO } from "src/api/UIClient";
import { StepDefinition, StepKey } from "./types";

export const getActiveOnboardingStep = (steps: Record<StepKey, StepDefinition>, data: OnboardingDTO): StepKey => {
  // Restore state
  const keys = Object.keys(steps);
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i] as StepKey;
    const step = steps[key];
    if (!step.requirementsMet(data)) {
      return key;
    }
  }

  return "organisation-type";
}
