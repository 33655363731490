import UserLayout from "src/layouts/UserLayout";
import OnboardingCard from "./components/OnboardingCard";
import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { API } from "src/api";
import { callApi } from "src/hooks/useApi";
import { DashboardDTO } from "src/api/UIClient";
import { useTranslation } from "react-i18next";
import { useToast } from "src/context/ToastContext";

const DashboardPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DashboardDTO>({});

  const { t } = useTranslation("common");
  const toast = useToast();

  const fetchData = async () => {
    const { data: res, error } = await callApi(() => API.dashboardGet());
    if (res) {
      setData(res);
      setLoading(false);
    } else {
      toast("error", t("oops"));
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onOnboardingHidden = () => setData({
    ...data,
    onboardingVisible: false,
  })

  return (
    <UserLayout>
      {loading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <React.Fragment>
          {data.onboardingVisible && (
            <OnboardingCard onHidden={onOnboardingHidden} />
          )}
        </React.Fragment>
      )}
    </UserLayout>
  );
};

export default DashboardPage;
