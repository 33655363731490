import { createContext, useContext, useRef } from "react";
import ConfirmDialog, { ConfirmDialogOptions, ConfirmDialogRef } from "src/components/ConfirmDialog";

export type ConfirmDialogContextType = {
  open: (options: ConfirmDialogOptions) => void;
};

export const ConfirmDialogContext = createContext<ConfirmDialogContextType>({
  open: () => {},
});

export const ConfirmDialogProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  const dialogRef = useRef<ConfirmDialogRef>(null)

  const value: ConfirmDialogContextType = {
    open: opts => dialogRef.current?.open(opts),
  }

  return <ConfirmDialogContext.Provider value={value}>
    <ConfirmDialog ref={dialogRef} />
    { children }
  </ConfirmDialogContext.Provider>
};

export type ConfirmDialogHook = (options: ConfirmDialogOptions) => void;

export const useConfirmDialog = (): ConfirmDialogHook => {
  const context = useContext(ConfirmDialogContext);

  return context.open;
}
