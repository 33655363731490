import i18n from "i18next"
import { initReactI18next } from "react-i18next";
import en from "src/assets/locales/en"

i18n
  .use(initReactI18next)
  .init({
    defaultNS: "common",
    ns: [ "common", "navmenu" ],
    debug: true,
    resources: {
      en,
    },
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
