import React, { useEffect, useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { CreateDeviceRequest } from "src/api/UIClient";
import { useTranslation } from "react-i18next";
import { callApi } from "src/hooks/useApi";
import { API, apiDeviceQR } from "src/api";
import { useToast } from "src/context/ToastContext";
import { Box, CircularProgress, Divider, InputAdornment, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { ResourceDeviceActivatedMessage } from "src/api/signalRMessageTypes";
import useSignalRMessage from "src/hooks/useSignalRMessage";
import useFunctionRef from "src/hooks/useFunctionRef";

type EmailButtonProps = {
  deviceId: string
}

const EmailButton = (props: EmailButtonProps) => {
  const { deviceId } = props;
  const { t } = useTranslation("devices");
  const [wait, setWait] = useState<number>(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setWait(it => it > 0 ? it - 1 : it);
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  })

  const sendEmail = () => {
    callApi(() => API.deviceSendTokenEmail(deviceId));
    setWait(30);
  }

  return <Button disabled={wait > 0} type="button" variant="contained" onClick={sendEmail}>{wait > 0 ? t("qrDialog.waitEmail", {wait}) : t("qrDialog.orUseEmail")}</Button>;
}

export type DeviceQRDialogProps = {
}

export type DeviceQRDialogHandle = {
  open: (employeeId: string | null, vehicleId: string | null) => void;
}

const DeviceQRDialog = React.forwardRef<DeviceQRDialogHandle, DeviceQRDialogProps>((props, ref) => {
  const { t } = useTranslation("devices");
  const toast = useToast();

  const [open, setOpen] = useState<boolean>(false);
  const [allowEmail, setAllowEmail] = useState<boolean>(false);
  const [registrationKey, setRegistrationKey] = useState<string | null>(null);
  const [completed, setCompleted] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  }

  const fetchQR = async (employeeId: string | null, vehicleId: string | null) => {
    const req: CreateDeviceRequest = {
      employeeId,
      vehicleId,
    };

    const { data: qrData, error: qrError } = await callApi(() =>
      API.deviceRegister(req)
    );
    if (qrError) {
      toast("error", t("common:oops"))
      console.error(qrError);
      return;
    }

    setRegistrationKey(qrData!.deviceKey!);
    setAllowEmail(employeeId !== null);
  }

  const deviceActivatedCallback = useFunctionRef(
    (msg: ResourceDeviceActivatedMessage) => {
      if (msg.deviceKey !== registrationKey) {
        return;
      }

      API.onboardingPostHide().then(() => {
        setCompleted(true);
      })
    },
    [registrationKey, setCompleted]
  );

  useSignalRMessage("deviceActivated", (msg: ResourceDeviceActivatedMessage) =>
    deviceActivatedCallback(msg)
  );

  useImperativeHandle(ref, () => ({
    open: (employeeId, vehicleId) => {
      setOpen(true);
      setRegistrationKey(null);
      setCompleted(false);
      fetchQR(employeeId, vehicleId);
    }
  }));

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">{t("qrDialog.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {
              registrationKey == null
                ? <CircularProgress />
                : completed == true
                  ? <>
                    <Icon
                      icon="tabler:discount-check"
                      color="#00aa00"
                      fontSize="6rem"
                      filter="drop-shadow(2px 5px 2px rgb(0 0 0 / 0.2))"
                    />
                    <Typography sx={{ mt: 1, mb: 1 }}>{t("qrDialog.completed")}</Typography>
                  </>
                  : <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <img src={apiDeviceQR(registrationKey)} alt="QR" />
                    <Typography sx={{ mt: 1 }}>{t("qrDialog.scanQR")}</Typography>
                    {
                      allowEmail && <Box sx={{ mt: 2 }}>
                        <Divider />
                        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                          <EmailButton deviceId={registrationKey} />
                        </Box>
                      </Box>
                    }
                  </Box>
            }
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions-dense">
        <Button type="button" onClick={handleClose}>{t("qrDialog.close")}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default DeviceQRDialog;
