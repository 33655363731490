import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Box from "@mui/system/Box";
import * as yup from "yup";
import {
  KratosLoginFlow,
  KratosLoginFlowLoginRequest,
  SubmitLoginFlowResult,
  SubmitLoginFlowResultErrorCode,
  UserResponse,
} from "src/api/UIClient";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "src/api";
import { AuthContext } from "src/context/AuthContext";
import { useNavigate } from "react-router-dom";
import CustomTextField from "src/@core/components/mui/text-field";
import { Icon } from "@iconify/react";
import Translations from "src/layouts/components/Translations";
import { useTranslation } from "react-i18next";
import useApi, { callApi } from "src/hooks/useApi";

const createSchema = (t: any) =>
  yup.object().shape({
    email: yup.string().email().required().label(t("login:email")),
    password: yup.string().min(5).required().label(t("login:password")),
  });

interface FormData {
  email: string;
  password: string;
}

interface FlowInitData {
  flow: KratosLoginFlow | null;
  error: boolean | null;
}

const doLogin = (req: KratosLoginFlowLoginRequest): Promise<UserResponse> =>
  API.authPostLoginFlow(req)
  .then(() => API.userWhoami())
  .catch((err) => {
    const result = err as SubmitLoginFlowResult;
    if (!!result.errorCode) {
      throw result.errorCode;
    }

    throw err;
  });

export interface LoginFormProps {}

const LoginForm = (props: LoginFormProps) => {
  const { login } = useContext(AuthContext);

  const nav = useNavigate();
  const { t } = useTranslation("login");

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [init, setInit] = useState<FlowInitData | null>(null);
  const {
    loading: submitingLogin,
    error: loginError,
    call: callLogin,
  } = useApi<UserResponse, SubmitLoginFlowResultErrorCode>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const fetchLoginFlow = async () => {
    const { data, error } = await callApi(() => API.authGetLoginFlow());
    setInit({
      flow: data ?? null,
      error: !!error,
    });
  };

  useEffect(() => {
    fetchLoginFlow();
  }, []);

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;

    const body: KratosLoginFlowLoginRequest = {
      flow: init!.flow!,
      username: email!,
      password: password!,
    };

    const { data: result } = await callLogin(() => doLogin(body));
    if (result) {
      login(result);
      nav("/");
    }
  };

  return init?.error && !loginError ? (
    <Alert variant="outlined" severity="error">
      {t("errorTryAgain")}
    </Alert>
  ) : (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 4 }}>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomTextField
              fullWidth
              autoFocus
              label={t("email")}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              {...(errors.email && {
                helperText: errors.email.message,
              })}
            />
          )}
        />
      </Box>
      <Box sx={{ mb: 1.5 }}>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <CustomTextField
              fullWidth
              value={value}
              onBlur={onBlur}
              label={t("password")}
              onChange={onChange}
              id="auth-login-v2-password"
              error={Boolean(errors.password)}
              {...(errors.password && {
                helperText: errors.password.message,
              })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon
                        fontSize="1.25rem"
                        icon={showPassword ? "tabler:eye" : "tabler:eye-off"}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      {submitingLogin ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CircularProgress disableShrink sx={{ mt: 6 }} />
        </Box>
      ) : (
        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{ mb: 4, mt: 4 }}
        >
          <Translations text="login:login" />
        </Button>
      )}
      {!!loginError && (
        <Alert variant="outlined" severity="error">
          {t(`apiError.${loginError}`)}
        </Alert>
      )}
    </form>
  );
};

export default LoginForm;
