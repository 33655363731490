import {
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import { CardContentProps } from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BlankLayout from "src/@core/layouts/BlankLayout";
import { API } from "src/api";
import { OnboardingDTO } from "src/api/UIClient";
import { AuthContext } from "src/context/AuthContext";
import { callApi } from "src/hooks/useApi";
import { AuthIllustrationV1Wrapper } from "./components/StyledComponents";
import { BaseStepProps, ONBOARDING_STEPS, StepKey } from "./types";
import OnboardingStepper from "./components/OnboardingStepper";
import { getActiveOnboardingStep } from "./utils";
import { useConfirmDialog } from "src/context/ConfirmDialogContext";
import { useToast } from "src/context/ToastContext";

const StepperHeaderContainer = styled(CardContent)<CardContentProps>(
  ({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      borderRight: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  })
);

const OnboardingPage = () => {
  const toast = useToast();

  const { t } = useTranslation("onboarding");
  const { logout, setConfig } = useContext(AuthContext);

  const nav = useNavigate();
  const confirm = useConfirmDialog();

  const [activeStep, setActiveStep] = useState<StepKey | null>(null);
  const [data, setData] = useState<OnboardingDTO | null>(null);

  const restoreOnboarding = async () => {
    const { data: res, error } = await callApi(() => API.onboardingGet());
    if (res) {
      setData(res);

      // Restore state
      const activeStep = getActiveOnboardingStep(ONBOARDING_STEPS, res);
      setActiveStep(activeStep);
    } else {
      toast("error", t("common:oops"));
      console.error(error);
    }
  };

  useEffect(() => {
    restoreOnboarding();
  }, []);

  const onSubmitData = (partialData?: Partial<OnboardingDTO>) => {
    const newData: OnboardingDTO = {
      ...data,
      ...partialData,
    };
    setData(newData);

    API.onboardingPost(newData);
  };

  const onChangeStep = (
    step: StepKey,
    submitData: Partial<OnboardingDTO> | null,
    set?: Partial<OnboardingDTO>
  ) => {
    setActiveStep(step);
    if (submitData) {
      onSubmitData(submitData);
    }
    if (set) {
      const newData: OnboardingDTO = {
        ...data,
        ...set,
      };
      setData(newData);
    }
  };

  const onSkip = () =>
    confirm({
      title: t("skipDialog.title"),
      content: t("skipDialog.content"),
      acceptLabel: t("skipDialog.skip"),
      declineLabel: t("skipDialog.cancel"),
      accept: () =>
        API.onboardingPostHide().then(() => {
          setConfig("onboarding_visible", false);
          nav("/");
        }),
    });

  const getStepContent = (step: StepKey) => {
    const props: BaseStepProps = {
      onChangeStep,
      onSubmitData,
      onSkip,
      data: data!,
    };

    const stepDef = ONBOARDING_STEPS[step];

    return stepDef.Component(props);
  };

  return (
    <BlankLayout>
      <Box className="content-center">
        <AuthIllustrationV1Wrapper>
          <Card
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            {activeStep === null ? (
              <Box
                sx={{
                  width: "100%",
                  mt: 8,
                  mb: 8,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CircularProgress disableShrink />
              </Box>
            ) : (
              <>
                <StepperHeaderContainer>
                  <OnboardingStepper
                    steps={ONBOARDING_STEPS}
                    activeStep={activeStep}
                  />
                </StepperHeaderContainer>
                <Divider sx={{ m: "0 !important" }} />
                <CardContent
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid item xs={12} mb={4} sx={{ flex: "0" }}>
                    <Typography className="font-medium" color="text.primary">
                      {t(ONBOARDING_STEPS[activeStep].title)}
                    </Typography>
                    <Typography variant="body2">
                      {t(ONBOARDING_STEPS[activeStep].subtitle)}
                    </Typography>
                  </Grid>
                  {getStepContent(activeStep)}
                </CardContent>
              </>
            )}
          </Card>
          <Box
            sx={{
              width: "100%",
              mt: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ textDecoration: "underline" }}
              variant="text"
              color="info"
              onClick={logout}
            >
              {t("common:logout")}
            </Button>
          </Box>
        </AuthIllustrationV1Wrapper>
      </Box>
    </BlankLayout>
  );
};

export default OnboardingPage;
