import { Icon } from "@iconify/react";
import { InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/@core/components/mui/text-field";
import StepButtonBar from "../components/StepButtonBar";
import { BaseStepProps } from "../types";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { OnboardingDTO } from "src/api/UIClient";

type StepEnterPTAProps = BaseStepProps;

const createSchema = (t: any) =>
  yup.object().shape({
    authorityName: yup.string().required().label(t("enterPta.authority")),
  });

const StepEnterPTA = (props: StepEnterPTAProps) => {
  const { onChangeStep, onSkip } = props;

  const { t } = useTranslation("onboarding");

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      authorityName: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = (data: Partial<OnboardingDTO>) =>
    onChangeStep("store-setup", data);

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "1", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
        <Box sx={{ flex: "1", maxWidth: 400 }}>
          <Controller
            name="authorityName"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                fullWidth
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                label={t("enterPta.authority")}
                placeholder={t("enterPta.authorityPlaceholder")}
                error={Boolean(errors.authorityName)}
                {...(errors.authorityName && {
                  helperText: errors.authorityName.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="mdi:city" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
      <StepButtonBar type="submit" disabled={isSubmitting} onSkip={onSkip} />
    </form>
  );
};

export default StepEnterPTA;
