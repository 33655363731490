import React, { useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import {
  CreateEmployeeRequest,
  GetEmployeeResponse,
  UpdateEmployeeRequest,
} from "src/api/UIClient";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "src/hooks/useApi";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";
import CustomTextField from "src/@core/components/mui/text-field";
import { InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";

const defaultData: GetEmployeeResponse = {};

const createSchema = (t: any) =>
  yup.object().shape({
    name: yup.string().required().label(t("edit.fields.name")),
    email: yup.string().required().label(t("edit.fields.email")),
  });

type FormData = {
  name: string;
  email: string;
};

export type EmployeeEditDialogProps = {
  refreshList: () => void;
};

export type EmployeeEditDialogRef = {
  open: (employee: GetEmployeeResponse | null) => void;
};

const EmployeeEditDialog = React.forwardRef<
  EmployeeEditDialogRef,
  EmployeeEditDialogProps
>((props, ref) => {
  const { refreshList } = props;

  const { t } = useTranslation("employees");
  const toast = useToast();

  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [editName, setEditName] = useState<string | null>(null);

  const { loading: saving, call } = useApi<any>();

  const handleClose = () => {
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
    } as FormData,
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  useImperativeHandle(ref, () => ({
    open: (employee) => {
      setEditId(employee?.id ?? null);
      setEditName(employee?.name ?? null);
      setOpen(true);

      const data = employee ?? defaultData;
      setValue("name", data.name!);
      setValue("email", data.email!);
    },
  }));

  const onSubmit = async (data: FormData) => {
    if (editId == null) {
      const req: CreateEmployeeRequest = {
        name: data.name!,
        email: data.email!,
      };

      const { error: createError } = await call(() => API.employeeCreate(req));
      if (createError) {
        toast("error", t("common:oops"));
        console.error(createError);
        return;
      }
    } else {
      const req: UpdateEmployeeRequest = {
        name: data.name!,
        email: data.email!,
      };

      const { error: createError } = await call(() =>
        API.employeeUpdate(editId!, req)
      );
      if (createError) {
        toast("error", t("common:oops"));
        console.error(createError);
        return;
      }
    }

    setOpen(false);
    refreshList();
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <DialogTitle id="alert-dialog-title">
          {editId == null
            ? t("edit.titleAdd")
            : t("edit.titleEdit", { name: editName })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomTextField
                  style={{ marginBottom: "1rem" }}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  label={t("edit.fields.name")}
                  placeholder={t("edit.fields.name")}
                  error={Boolean(errors.name)}
                  {...(errors.name && {
                    helperText: errors.name.message,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon="mdi:person" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomTextField
                  fullWidth
                  value={value}
                  onChange={onChange}
                  label={t("edit.fields.email")}
                  placeholder={t("edit.fields.email")}
                  error={Boolean(errors.email)}
                  {...(errors.email && {
                    helperText: errors.email.message,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon="mdi:mail" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions-dense">
          <Button type="button" onClick={handleClose}>
            {t("edit.actionCancel")}
          </Button>
          <Button type="submit" disabled={saving}>
            {t("edit.actionSave")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default EmployeeEditDialog;
