import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export interface StepNextButtonProps {
  onNext?: () => void;
  onSkip?: () => void;
  icon?: string;
  text?: string | null;
  type?: string;
  disabled?: boolean;
}

const StepButtonBar = (props: StepNextButtonProps) => {
  const { onNext, onSkip, icon, text, type, disabled } = props;

  const { t } = useTranslation("onboarding");

  return (
    <Box sx={{ display: "flex", mt: 4, justifyContent: onSkip ? "space-between" : "end" }}>
      {onSkip && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={onSkip}
          type="button"
          disabled={disabled}
          sx={{ alignSelf: "end" }}
          startIcon={<Icon icon={icon ?? "tabler:run"} />}
        >
          {t("skip")}
        </Button>
      )}
      <Button
        variant="contained"
        color="success"
        onClick={onNext}
        type={type as any}
        disabled={disabled}
        sx={{ alignSelf: "end" }}
        endIcon={<Icon icon={icon ?? "tabler:arrow-right"} />}
      >
        {t(text ?? "next")}
      </Button>
    </Box>
  );
};

export default StepButtonBar;
