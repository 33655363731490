import { Link } from "@mui/material";
import { Box, BoxProps, styled } from "@mui/system";

export const AuthIllustrationV1Wrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  minWidth: 400,
  maxWidth: "60vw",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    "&:before": {
      zIndex: -1,
      top: "-79px",
      content: '""',
      left: "-46px",
      width: "238px",
      height: "234px",
      position: "absolute",
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%23${theme.palette.primary.main.slice(
        1
      )}' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%23${theme.palette.primary.main.slice(
        1
      )}' fill-opacity='0.08'/%3E%3C/svg%3E")`,
    },
    "&:after": {
      zIndex: -1,
      content: '""',
      width: "180px",
      right: "-57px",
      height: "180px",
      bottom: "-54px",
      position: "absolute",
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%23${theme.palette.primary.main.slice(
        1
      )}' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%23${theme.palette.primary.main.slice(
        1
      )}' fill-opacity='0.08'/%3E%3C/svg%3E")`,
    },
  },
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: `${theme.palette.primary.main} !important`,
}));
