import { Icon } from "@iconify/react";
import { InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/@core/components/mui/text-field";
import { BaseStepProps } from "../types";
import StepButtonBar from "../components/StepButtonBar";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateVehicleRequest, OnboardingDTO } from "src/api/UIClient";
import useApi from "src/hooks/useApi";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";

const createSchema = (t: any) =>
  yup.object().shape({
    vehicleRegistrationNumber: yup
      .string()
      .required()
      .label(t("createEmployee.name")),
  });

type StepCreateEmployeeProps = BaseStepProps;

const StepCreateVehicle = (props: StepCreateEmployeeProps) => {
  const { onChangeStep, onSkip } = props;

  const toast = useToast();

  const { t } = useTranslation("onboarding");
  const { loading: savingVehicle, call: callCreateVehicle } = useApi<string>();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      vehicleRegistrationNumber: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = async (data: Partial<OnboardingDTO>) => {
    const req: CreateVehicleRequest = {
      registrationNumber: data.vehicleRegistrationNumber,
    };

    const { error: createError } = await callCreateVehicle(() =>
      API.vehicleCreate(req)
    );
    if (createError) {
      toast("error", t("common:oops"));
      console.error(createError);
      return;
    }

    onChangeStep("add-device", data);
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "1", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
        <Box sx={{ flex: "1", maxWidth: 400 }}>
          <Controller
            name="vehicleRegistrationNumber"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t("createVehicle.registrationNumber")}
                placeholder={t("createVehicle.registrationNumberPlaceholder")}
                error={Boolean(errors.vehicleRegistrationNumber)}
                {...(errors.vehicleRegistrationNumber && {
                  helperText: errors.vehicleRegistrationNumber.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="mdi:bus" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
      <StepButtonBar
        type="submit"
        disabled={isSubmitting || savingVehicle}
        onSkip={onSkip}
      />
    </form>
  );
};

export default StepCreateVehicle;
