import BlankLayout from "src/@core/layouts/BlankLayout";
import LoginForm from "./components/LoginForm";
import { Box, BoxProps, styled, useTheme } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";
import FooterIllustrationsV2 from "src/IllustrationV2";
import LoginIllustrationAsset from "src/assets/images/auth-v2-login-illustration-light.png";
import { useTranslation } from "react-i18next";

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 450,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 600,
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 750,
  },
}));

const LoginIllustration = styled("img")(({ theme }) => ({
  zIndex: 2,
  maxHeight: 680,
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(12),
  [theme.breakpoints.down(1540)]: {
    maxHeight: 550,
  },
  [theme.breakpoints.down("lg")]: {
    maxHeight: 500,
  },
}));

const LoginPage = () => {
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation("login");

  return (
    <BlankLayout>
      <Box
        className="content-right"
        sx={{ backgroundColor: "background.paper" }}
      >
        {!hidden ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              position: "relative",
              alignItems: "center",
              borderRadius: "20px",
              justifyContent: "center",
              backgroundColor: "customColors.bodyBg",
              margin: (theme) => theme.spacing(8, 0, 8, 8),
            }}
          >
            <LoginIllustration
              alt="login-illustration"
              src={LoginIllustrationAsset}
            />
            <FooterIllustrationsV2 />
          </Box>
        ) : null}
        <RightWrapper>
          <Box
            sx={{
              p: [6, 12],
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%", maxWidth: 400 }}>
              <svg
                width={32}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    gradientUnits="userSpaceOnUse"
                    x1="10"
                    y1="0"
                    x2="10"
                    y2="20"
                    id="gradient-0"
                  >
                    <stop
                      offset="0"
                      style={{ stopColor: "rgb(88.627% 59.216% 1.5686%)" }}
                    ></stop>
                    <stop
                      offset="1"
                      style={{ stopColor: "rgb(53.32% 33.576% 0%)" }}
                    ></stop>
                  </linearGradient>
                </defs>
                <rect
                  width="20"
                  height="20"
                  style={{ fill: "url(#gradient-0)" }}
                ></rect>
                <path
                  d="M 11.472 2.975 L 7.94 2.975 C 7.81 3.458 7.517 5.78 7.474 6.133 L 5.04 6.133 L 5.04 8.532 L 7.468 8.532 L 7.468 14.053 C 7.468 14.915 7.797 15.582 8.473 16.093 C 9.15 16.604 10.033 16.852 11.175 16.852 C 12.495 16.859 13.814 16.802 15.125 16.685 L 15.125 14.551 C 14.666 14.551 13.601 14.551 12.811 14.551 C 12.634 14.553 12.459 14.529 12.296 14.478 C 12.134 14.427 11.985 14.353 11.86 14.259 C 11.736 14.165 11.639 14.053 11.572 13.929 C 11.506 13.805 11.474 13.674 11.476 13.54 L 11.476 8.466 L 15.138 8.466 L 15.138 6.197 L 11.472 6.197 L 11.472 2.975 Z"
                  style={{ fill: "rgb(255, 255, 255)" }}
                ></path>
              </svg>
              <Box sx={{ my: 6 }}>
                <Typography variant="h3" sx={{ mb: 1.5 }}>
                  {t("welcome")}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {t("pleaseLogin")}
                </Typography>
              </Box>

              <LoginForm />
            </Box>
          </Box>
        </RightWrapper>
      </Box>
    </BlankLayout>
  );
};

export default LoginPage;
