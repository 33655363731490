import { Icon } from "@iconify/react";
import { InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/@core/components/mui/text-field";
import { BaseStepProps } from "../types";
import StepButtonBar from "../components/StepButtonBar";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateEmployeeRequest, OnboardingDTO } from "src/api/UIClient";
import useApi from "src/hooks/useApi";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";

const createSchema = (t: any) =>
  yup.object().shape({
    employeeName: yup.string().required().label(t("createEmployee.name")),
    employeeEmail: yup.string().required().label(t("createEmployee.email")),
  });

type StepCreateEmployeeProps = BaseStepProps;

const StepCreateEmployee = (props: StepCreateEmployeeProps) => {
  const { onChangeStep, onSkip } = props;

  const toast = useToast();

  const { t } = useTranslation("onboarding");
  const { loading: savingEmployee, call: callCreateEmployee } =
    useApi<string>();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      employeeName: "",
      employeeEmail: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = async (data: Partial<OnboardingDTO>) => {
    const req: CreateEmployeeRequest = {
      name: data.employeeName,
      email: data.employeeEmail,
    };

    const { error: createError } = await callCreateEmployee(() =>
      API.employeeCreate(req)
    );
    if (createError) {
      toast("error", t("common:oops"));
      console.error(createError);
      return;
    }

    onChangeStep("create-vehicle", data);
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ flex: "1", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
        <Box sx={{ flex: "1", maxWidth: 400 }}>
          <Controller
            name="employeeName"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                value={value}
                onChange={onChange}
                label={t("createEmployee.name")}
                placeholder={t("createEmployee.namePlaceholder")}
                error={Boolean(errors.employeeName)}
                {...(errors.employeeName && {
                  helperText: errors.employeeName.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="mdi:person" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name="employeeEmail"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                fullWidth
                value={value}
                onChange={onChange}
                label={t("createEmployee.email")}
                placeholder={t("createEmployee.emailPlaceholder")}
                error={Boolean(errors.employeeEmail)}
                {...(errors.employeeEmail && {
                  helperText: errors.employeeEmail.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon icon="mdi:mail" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Box>
      <StepButtonBar
        type="submit"
        disabled={isSubmitting || savingEmployee}
        onSkip={onSkip}
      />
    </form>
  );
};

export default StepCreateEmployee;
