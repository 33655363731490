import React, {
  createContext, useContext, useEffect, useState,
} from "react";
import { AuthContext } from "./AuthContext";
import { HubConnection, HubConnectionBuilder, IRetryPolicy, RetryContext } from "@microsoft/signalr";
import { useToast } from "./ToastContext";
import { useTranslation } from "react-i18next";

export interface SignalRContextType {
  connection: HubConnection | null
}

export const SignalRContext = createContext<SignalRContextType>({
  connection: null,
});

class RetryPolicy implements IRetryPolicy {
  nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
    return 5000;
  }
}

export const SignalRContextProvider = (props: React.PropsWithChildren<{}>) => {
  const toast = useToast();
  const { t } = useTranslation("translation");

  const { user } = useContext(AuthContext)
  const [ connection, setConnection ] = useState<HubConnection | null>(null)

  useEffect(() => {
    if (user && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl("/hub")
        .withAutomaticReconnect(new RetryPolicy())
        .build();
      newConnection.start().catch(err => {
        toast("error", t("oops"));
        console.error(err)
      });
      setConnection(newConnection);
    } else if (!user && connection) {
      connection.stop()
      setConnection(null)
    }
  }, [ user ]);

  const value = {
    connection,
  }

  return (
    <SignalRContext.Provider value={value}>
      <>{props.children}</>
    </SignalRContext.Provider>
  );
};
