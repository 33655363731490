import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./@core/context/settingsContext";
import ThemeComponent from "./@core/theme/ThemeComponent";
import LoginPage from "./pages/auth/LoginPage";
import Error404 from "./pages/404";
import "./i18n";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";
import React, { Suspense, useContext } from "react";
import FallbackSpinner from "./@core/components/spinner";
import OnboardingPage from "./pages/onboarding/OnboardingPage";
import { SignalRContextProvider } from "./context/SignalRContext";
import VehiclesPage from "./pages/vehicles/VehiclesPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import {
  ROUTE_NETWORK,
  ROUTE_EMPLOYEES,
  ROUTE_ONBOARDING,
  ROUTE_VEHICLES,
} from "./routes";
import { ConfirmDialogProvider } from "./context/ConfirmDialogContext";
import { ToastProvider } from "./context/ToastContext";
import NetworkPage from "./pages/network/NetworkPage";
import EmployeesPage from "./pages/employees/EmployeesPage";

const AuthRedirect = ({
  hasUser,
  to,
  children,
}: React.PropsWithChildren<{ hasUser: boolean; to: string }>) => {
  const auth = useContext(AuthContext);

  if ((hasUser && auth.user) || (!hasUser && !auth.user)) {
    return <Navigate to={to} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const OnboardingRedirect = () => {
  const auth = useContext(AuthContext);

  if (auth.user && auth.user.config?.["onboarding_visible"] === true) {
    return <Navigate to={`/${ROUTE_ONBOARDING}`} />;
  }

  return <Outlet />;
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Protected routes */}
        <Route
          path="/"
          element={
            <AuthRedirect hasUser={false} to="/login">
              <OnboardingRedirect />
            </AuthRedirect>
          }
        >
          { /* Vehicles */ }
          <Route path={ROUTE_VEHICLES} element={<VehiclesPage />} />

          { /* Employees */ }
          <Route path={ROUTE_EMPLOYEES} element={<EmployeesPage />} />

          { /* Network */ }
          <Route path={ROUTE_NETWORK} element={<NetworkPage />} />

          <Route index element={<DashboardPage />} />
        </Route>

        {/* Unprotected rotues */}
        <Route
          path={`/${ROUTE_ONBOARDING}`}
          element={
            <AuthRedirect hasUser={false} to="/login">
              <OnboardingPage />
            </AuthRedirect>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRedirect hasUser to="/">
              <LoginPage />
            </AuthRedirect>
          }
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  return (
    <SettingsProvider>
      <Suspense fallback={<FallbackSpinner />}>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeComponent settings={settings}>
              <ToastProvider>
                <AuthContextProvider>
                  <SignalRContextProvider>
                    <ConfirmDialogProvider>
                      <Router />
                    </ConfirmDialogProvider>
                  </SignalRContextProvider>
                </AuthContextProvider>
              </ToastProvider>
            </ThemeComponent>
          )}
        </SettingsConsumer>
      </Suspense>
    </SettingsProvider>
  );
}

export default App;
