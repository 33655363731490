import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomRadioIcons from "src/@core/components/custom-radio/icons";
import { CustomRadioIconsData } from "src/@core/components/custom-radio/types";
import { BaseStepProps } from "../types";
import StepButtonBar from "../components/StepButtonBar";
import { OnboardingDTOOrganisationType } from "src/api/UIClient";

const createOptionsData = (t: any): CustomRadioIconsData[] => [
  {
    value: OnboardingDTOOrganisationType.PTA,
    content: t("stepType.descPta"),
    title: (
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t("stepType.imPta")}
      </Typography>
    ),
  },
  {
    value: OnboardingDTOOrganisationType.PTO,
    content: t("stepType.descPto"),
    title: (
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t("stepType.imPto")}
      </Typography>
    ),
  },
];

const icons: string[] = ["mdi:city", "mdi:bus"];

export type StepTypeProps = BaseStepProps;

const StepType = (props: StepTypeProps) => {
  const { onChangeStep, onSkip } = props;

  const theme = useTheme();

  const { t } = useTranslation("onboarding");
  const options = createOptionsData(t);

  const [selectedRadio, setSelectedRadio] =
    useState<OnboardingDTOOrganisationType>(OnboardingDTOOrganisationType.PTA);

  const handleRadioChange = (prop: string | ChangeEvent<HTMLInputElement>) => {
    if (typeof prop === "string") {
      setSelectedRadio(prop as OnboardingDTOOrganisationType);
    } else {
      setSelectedRadio(
        (prop.target as HTMLInputElement).value as OnboardingDTOOrganisationType
      );
    }
  };

  const onNext = () => {
    onChangeStep("enter-pta", {
      organisationType: selectedRadio,
    });
  };

  return (
    <>
      <Box
        sx={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: 400 }}>
          {options.map((it, index) => (
            <Box sx={{ mb: 2 }}>
              <CustomRadioIcons
                key={index}
                data={it}
                name="custom-radios"
                icon={icons[index]}
                selected={selectedRadio}
                gridProps={{ sm: 4, xs: 12 }}
                handleChange={handleRadioChange}
                iconProps={{
                  fontSize: "2.5rem",
                  style: { marginBottom: 8 },
                  color: theme.palette.text.secondary,
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <StepButtonBar onNext={onNext} onSkip={onSkip} />
    </>
  );
};

export default StepType;
