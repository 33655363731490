import { useContext } from "react";
import {
  LIST_CARD_PAGESIZE,
  ListCardPaginationContext,
} from "./ListCardPaginationContext";
import { DataTableFooterComponent } from "./PaginationFooter";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

export type ViewModeCardProps = {
  data: any[];
  toggleViewMode: () => void;

  columns: GridColumns<any>;
};

const ViewModeCard = (props: ViewModeCardProps) => {
  const { data, toggleViewMode, columns } = props;

  const paginationState = useContext(ListCardPaginationContext);

  return (
    <DataGrid
      rowsPerPageOptions={LIST_CARD_PAGESIZE}
      initialState={{
        pagination: {
          page: paginationState.page,
          pageSize: paginationState.pageSize,
        },
      }}
      rowHeight={62}
      disableSelectionOnClick
      columns={columns}
      loading={!data}
      rows={data ?? []}
      components={{
        Footer: DataTableFooterComponent,
      }}
      componentsProps={{
        footer: {
          viewMode: "list",
          toggleViewMode,
        },
      }}
    />
  );
};

export default ViewModeCard;
