import { InputAdornment, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { BaseStepProps } from "../types";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import CustomTextField from "src/@core/components/mui/text-field";
import StepButtonBar from "../components/StepButtonBar";
import { Icon } from "@iconify/react";
import { API, apiDeviceQR } from "src/api";
import useSignalRMessage from "src/hooks/useSignalRMessage";
import { ResourceDeviceActivatedMessage } from "src/api/signalRMessageTypes";
import useFunctionRef from "src/hooks/useFunctionRef";
import { useToast } from "src/context/ToastContext";
import { CreateDeviceRequest } from "src/api/UIClient";
import { callApi } from "src/hooks/useApi";

const createSchema = (t: any) =>
  yup.object().shape({
    deviceType: yup
      .string()
      .required()
      .oneOf(["fixed", "portable"])
      .label(t("addDevice.fields.deviceType")),
  });

export type StepAddDeviceProps = BaseStepProps;

type FormData = {
  deviceType: string;
};

const StepAddDevice = (props: StepAddDeviceProps) => {
  const { onChangeStep, onSkip } = props;

  const toast = useToast();

  const { t } = useTranslation("onboarding");
  const [registrationKey, setRegistrationKey] = useState<string | null>(null);

  const deviceActivatedCallback = useFunctionRef(
    (msg: ResourceDeviceActivatedMessage) => {
      if (msg.deviceKey !== registrationKey) {
        return;
      }

      API.onboardingPostHide().then(() => {
        onChangeStep("completed", {
          deviceConnected: true,
        });
      })
    },
    [registrationKey, onChangeStep]
  );

  useSignalRMessage("deviceActivated", (msg: ResourceDeviceActivatedMessage) =>
    deviceActivatedCallback(msg)
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      deviceType: "fixed",
    } as FormData,
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = async (data: FormData) => {
    const req: CreateDeviceRequest = { };

    if (data.deviceType === "fixed") {
      const { data: vehicles, error: vehiclesError } = await callApi(() =>
        API.vehicleGetAll()
      );

      if (vehiclesError) {
        toast("error", t("common:oops"))
        console.error(vehiclesError);
        return;
      }
      if (vehicles!.length <= 0) {
        toast("error", t("common:oops"))
        console.error("No vehicles");
        return;
      }

      req.vehicleId = vehicles![0].id;
    } else if (data.deviceType === "portable") {
      const { data: employees, error: employeesError } = await callApi(() =>
        API.vehicleGetAll()
      );

      if (employeesError) {
        toast("error", t("common:oops"))
        console.error(employeesError);
        return;
      }
      if (employees!.length <= 0) {
        toast("error", t("common:oops"))
        console.error("No employees");
        return;
      }

      req.employeeId = employees![0].id;
    } else {
      console.error("Unknown type");
      return;
    }

    const { data: qrData, error: qrError } = await callApi(() =>
      API.deviceRegister(req)
    );
    if (qrError) {
      toast("error", t("common:oops"))
      console.error(qrError);
      return;
    }

    setRegistrationKey(qrData!.deviceKey!);
  };

  return (
    <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
      {registrationKey ? (
        <Box>
          <Typography sx={{ mb: 3, textAlign: "center" }}>{t("addDevice.scanQr")}</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={apiDeviceQR(registrationKey)} alt="QR" />
          </Box>
        </Box>
      ) : (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ flex: "1", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
            <Box sx={{ flex: "1", maxWidth: 400 }}>
              <Controller
                name="deviceType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomTextField
                    select
                    fullWidth
                    id="custom-select"
                    label={t("addDevice.type")}
                    sx={{ mb: 2 }}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.deviceType)}
                    {...(errors.deviceType && {
                      helperText: errors.deviceType.message,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon="ph:device-mobile" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key={0} value="fixed">
                      {t("devices:deviceTypes.fixed")}
                    </MenuItem>
                    <MenuItem key={1} value="portable">
                      {t("devices:deviceTypes.portable")}
                    </MenuItem>
                  </CustomTextField>
                )}
              />
            </Box>
          </Box>
          <StepButtonBar
            type="submit"
            disabled={isSubmitting}
            onSkip={onSkip}
          />
        </form>
      )}
    </Box>
  );
};

export default StepAddDevice;
