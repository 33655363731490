import { createContext, useState } from "react";

export const LIST_CARD_PAGESIZE = [ 10, 25, 50 ];

export type ListCardPaginationState = {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
};

export const ListCardPaginationContext = createContext<ListCardPaginationState>({
  page: 0,
  pageSize: 1,
  setPage: () => {},
  setPageSize: () => {},
})

export type ListCardPaginationProviderProps = {
  initialPage?: number;
  initialPageSize?: number;
}

export const ListCardPaginationProvider = (props: React.PropsWithChildren<ListCardPaginationProviderProps>) => {
  const [page, setPage] = useState(props.initialPage ?? 0);
  const [pageSize, setPageSize] = useState(props.initialPageSize ?? LIST_CARD_PAGESIZE[0]);

  const value: ListCardPaginationState = {
    page,
    pageSize,
    setPage,
    setPageSize,
  }

  return <ListCardPaginationContext.Provider value={value}>
    { props.children }
  </ListCardPaginationContext.Provider>
}
