import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import UserLayout from "src/layouts/UserLayout";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { GetEmployeeResponse } from "src/api/UIClient";
import { callApi } from "src/hooks/useApi";
import { API } from "src/api";
import { Button, IconButton, styled } from "@mui/material";
import Icon from "src/@core/components/icon";
import { useConfirmDialog } from "src/context/ConfirmDialogContext";
import ListCardView from "src/components/list-card-view/ListCardView";
import { useToast } from "src/context/ToastContext";
import EmployeeEditDialog, { EmployeeEditDialogRef } from "./EmployeeEditDialog";
import DeviceQRDialog, { DeviceQRDialogHandle } from "src/components/DeviceQRDialog";

interface CellType {
  row: GetEmployeeResponse;
}

const RowOptions = ({ onQR, onEdit, onDelete }: { onQR: () => void, onEdit: () => void, onDelete: () => void }) => {
  return (
    <>
      <IconButton onClick={onQR}>
        <Icon icon="tabler:qrcode" fontSize={24} />
      </IconButton>
      <IconButton onClick={onEdit}>
        <Icon icon="tabler:edit" fontSize={24} />
      </IconButton>
      <IconButton onClick={onDelete}>
        <Icon icon="tabler:trash" fontSize={24} />
      </IconButton>
    </>
  );
};

const EmployeeCardWrapper = styled("div")({
  minWidth: "400px",
  padding: "5px",
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: "25%",
});

const Layout = styled("div")({
  border: "1px solid #dbdade",
  borderRadius: "4px",
  padding: "0.5rem",
});

const IconWrapper = styled("div")({
  width: "100%",
  minHeight: "75px",
  borderRadius: "4px",
  background: "#dedede",
  display: "flex",
  flexFlow: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1rem",
});

const EmployeeCard = (props: { data: GetEmployeeResponse }) => {
  const { data } = props;

  return (
    <EmployeeCardWrapper key={data.id}>
      <Layout>
        <IconWrapper>
          <Icon icon="tabler:user" fontSize="3rem" />
        </IconWrapper>
        {data.name}
      </Layout>
    </EmployeeCardWrapper>
  );
};

const EmployeesPage = () => {
  const toast = useToast();
  const { t } = useTranslation("employees");

  const confirm = useConfirmDialog();

  const employeeEditDialog = useRef<EmployeeEditDialogRef>(null);
  const deviceDialog = useRef<DeviceQRDialogHandle>(null);

  const columns = useMemo(
    () => [
      {
        flex: 0.5,
        field: "name",
        minWidth: 80,
        headerName: t("columns.name"),
      },
      {
        flex: 0.1,
        minWidth: 100,
        sortable: false,
        field: "actions",
        headerName: t("columns.actions"),
        renderCell: ({ row }: CellType) => (
          <RowOptions
            onQR={() => deviceDialog.current?.open(row.id!, null)}
            onEdit={() => employeeEditDialog.current?.open(row)}
            onDelete={() =>
              confirm({
                title: "Delete the vehicle?",
                content:
                  "Are you sure want to delete the vehicle? This cannot be undone!",
                acceptLabel: "Delete",
                declineLabel: "Cancel",
              })
            }
          />
        ),
      },
    ],
    [t]
  );

  const [employees, setEmployees] = useState<GetEmployeeResponse[] | null>(null);
  const fetchEmployees = async () => {
    const { data, error } = await callApi(() => API.employeeGetAll());
    if (error) {
      toast("error", t("common:oops"));
      console.error(error);
      return;
    }

    setEmployees(data!);
  };

  const onAddEmployee = () => employeeEditDialog.current?.open(null);

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <UserLayout>
      <EmployeeEditDialog ref={employeeEditDialog} refreshList={fetchEmployees} />
      <DeviceQRDialog ref={deviceDialog} />
      <Card>
        <CardHeader
          title={t("title")}
          action={
            <Button variant="contained" sx={{ "& svg": { mr: 2 } }} onClick={onAddEmployee}>
              <Icon fontSize="1.125rem" icon="tabler:plus" />
              {t("addAction")}
            </Button>
          }
        />
        <Box sx={{ height: 500 }}>
          <ListCardView
            loading={employees == null}
            data={employees ?? []}
            columns={columns}
            renderCard={(data) => <EmployeeCard key={data.id} data={data} />}
          />
        </Box>
      </Card>
    </UserLayout>
  );
};

export default EmployeesPage;
