import { Icon } from "@iconify/react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { API } from "src/api";
import { useConfirmDialog } from "src/context/ConfirmDialogContext";
import { useToast } from "src/context/ToastContext";
import { callApi } from "src/hooks/useApi";
import OnboardingStepper from "src/pages/onboarding/components/OnboardingStepper";
import { ONBOARDING_STEPS, StepKey } from "src/pages/onboarding/types";
import { getActiveOnboardingStep } from "src/pages/onboarding/utils";
import { ROUTE_ONBOARDING } from "src/routes";

type OnboardingCardProps = {
  onHidden: () => void
}

const OnboardingCard = (props: OnboardingCardProps) => {
  const {
    onHidden,
  } = props;

  const toast = useToast();
  const { t } = useTranslation("onboarding");

  const nav = useNavigate();
  const confirm = useConfirmDialog();

  const [activeStep, setActiveStep] = useState<StepKey | null>(null);

  const restoreOnboarding = async () => {
    const {
      data: res,
      error,
    } = await callApi(() => API.onboardingGet());
    if (res) {
      const activeStep = getActiveOnboardingStep(ONBOARDING_STEPS, res);
      setActiveStep(activeStep);
    } else {
      toast("error", t("common:oops"));
      console.error(error);
    }
  };

  useEffect(() => {
    restoreOnboarding();
  }, []);

  const onNavigateToOnboarding = () => nav(ROUTE_ONBOARDING);

  const onHide = () => confirm({
    title: t("dashboard.hideTitle"),
    content: t("dashboard.hideContent"),
    acceptLabel: t("dashboard.acceptHide"),
    declineLabel: t("dashboard.declineHide"),
    accept: () => {
      API.dashboardOnboardingHide();
      onHidden();
    }
  })

  return (
    <Card>
      <CardHeader
        title={t("dashboard.title")}
        action={
          <Tooltip title={t("dashboard.hide")}>
            <IconButton onClick={onHide}>
              <Icon icon="tabler:eye-closed" />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        {activeStep === null ? (
          <CircularProgress disableShrink />
        ) : (
          <div style={{ cursor: "pointer" }} onClick={onNavigateToOnboarding}>
            <OnboardingStepper
              steps={ONBOARDING_STEPS}
              activeStep={activeStep}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default OnboardingCard;
