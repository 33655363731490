import { Card, CardHeader } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import UserLayout from "src/layouts/UserLayout";
import Map, { MapHandle } from "./components/Map";
import {
  GetRoutePointResponse,
  GetStopPointResponse,
} from "src/api/UIClient";
import { useRef } from "react";
import { callApi } from "src/hooks/useApi";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";
import LinesMenu from "./components/LinesMenu";

const MapLayout = styled("div")({
  height: "650px",
  display: "flex",
});

const MapSidebar = styled("div")(({ theme }) => ({
  flex: "0 0 330px",
  display: "flex",
  flexDirection: "column",
  backgroundColor:
    theme.palette.mode === "light"
      ? "rgba(128,128,128,0.05)"
      : "rgba(255,255,255,0.05)",
}));

const MapWrapper = styled("div")({
  flex: "1 1 auto",
});

const NetworkPage = () => {
  const { t } = useTranslation("network");
  const toast = useToast();

  const mapRef = useRef<MapHandle>(null);

  const onRouteSelect = async (routeId: string | null) => {
    if (routeId) {
      const { data, error } = await callApi<GetRoutePointResponse[]>(() =>
        API.networkGetRoutePoints(routeId)
      );

      if (error) {
        console.error(error);
        toast("error", t("common:oops"));
        return;
      }
 
      mapRef.current!.setRoutePoints(data!);
    } else {
      mapRef.current!.setRoutePoints([]);
    }
  }

  const onStopsMode = (stops: GetStopPointResponse[]) => {
    mapRef.current!.setStopPoints(stops);
  }

  const onStopSelected = (index: number) => {
    mapRef.current!.showStopPoint(index);
  }

  return (
    <UserLayout>
      <Card sx={{ display: "flex", flexDirection: "column" }}>
        <CardHeader sx={{ flex: "0" }} title={t("title")} />
        <MapLayout>
          <MapSidebar>
            <LinesMenu onRouteSelect={onRouteSelect} onStopsMode={onStopsMode} onStopSelected={onStopSelected} />
          </MapSidebar>
          <MapWrapper>
            <Map ref={mapRef} />
          </MapWrapper>
        </MapLayout>
      </Card>
    </UserLayout>
  );
};

export default NetworkPage;
