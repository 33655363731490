import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import StepButtonBar from "../components/StepButtonBar";
import { BaseStepProps } from "../types";

export type StepCompleteProps = BaseStepProps;

const StepWrapper = styled(Box)({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StepComplete = (props: StepCompleteProps) => {
  const { t } = useTranslation("onboarding");

  const onFinish = () => (window.location.href = "/"); // Reload the page

  return (
    <>
      <StepWrapper>
        <Icon
          icon="tabler:discount-check"
          color="#00aa00"
          fontSize="6rem"
          filter="drop-shadow(2px 5px 2px rgb(0 0 0 / 0.2))"
        />
        <Typography sx={{ mt: 1, mb: 1 }}>{t("completed.done")}</Typography>
      </StepWrapper>
      <StepButtonBar onNext={onFinish} icon="tabler:check" text="done" />
    </>
  );
};

export default StepComplete;
