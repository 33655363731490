import { StepLabel, Stepper, Typography } from "@mui/material";
import type { StepProps } from "@mui/material/Step";
import MuiStep from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import StepperCustomDot from "./StepperCustomDot";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import { Icon } from "@iconify/react";
import { StepDefinition, StepKey } from "../types";
import Avatar from "src/@core/components/mui/avatar";
import { useTranslation } from "react-i18next";
import StepperWrapper from "./StepperWrapper";

const Step = styled(MuiStep)<StepProps>(({ theme }) => ({
  "& .MuiStepLabel-root": {
    paddingTop: 0,
  },
  "&:not(:last-of-type) .MuiStepLabel-root": {
    paddingBottom: theme.spacing(6),
  },
  "&:last-of-type .MuiStepLabel-root": {
    paddingBottom: 0,
  },
  "& .MuiStepLabel-iconContainer": {
    display: "none",
  },
  "& .step-subtitle": {
    color: `${theme.palette.text.disabled} !important`,
  },
  "& + svg": {
    color: theme.palette.text.disabled,
  },
  "&.Mui-completed .step-title": {
    color: theme.palette.text.disabled,
  },
}));

type OnboardingStepperProps = {
  steps: Record<StepKey, StepDefinition>;
  activeStep: StepKey;
};

const OnboardingStepper = (props: OnboardingStepperProps) => {
  const { steps, activeStep } = props;

  const { t } = useTranslation("onboarding");

  return (
    <StepperWrapper sx={{ height: "100%" }}>
      <Stepper
        activeStep={steps[activeStep].order}
        orientation="vertical"
        connector={<></>}
        sx={{ height: "100%", minWidth: "15rem" }}
      >
        {Object.values(steps).map((step, index) => {
          const stepOrder = step.order;
          const activeSteporder = steps[activeStep].order;

          return (
            <Step key={index}>
              <StepLabel StepIconComponent={StepperCustomDot}>
                <div className="step-label">
                  <Avatar
                    variant="rounded"
                    skin={activeSteporder !== stepOrder ? "light" : "filled"}
                    color={activeSteporder >= stepOrder ? "primary" : undefined}
                    sx={{
                      ...(activeSteporder === stepOrder && {
                        boxShadow: (theme) => theme.shadows[3],
                      }),
                      ...(activeSteporder > stepOrder && {
                        color: (theme) =>
                          hexToRGBA(theme.palette.primary.main, 0.4),
                      }),
                    }}
                  >
                    <Icon icon={step.icon} />
                  </Avatar>
                  <div>
                    <Typography className="step-title">
                      {t(step.title)}
                    </Typography>
                    <Typography className="step-subtitle">
                      {t(step.subtitle)}
                    </Typography>
                  </div>
                </div>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </StepperWrapper>
  );
};

export default OnboardingStepper;
