import { DependencyList, useEffect, useRef } from "react"

const useFunctionRef = (func: Function, deps: DependencyList): any => {
  const functionRef = useRef<Function>()

  useEffect(() => {
    functionRef.current = func
  }, deps)

  return (...args: any[]) => {
    if (functionRef.current != null) {
      functionRef.current(...args)
    }
  }
}

export default useFunctionRef
