import { Feature, Map, View } from "ol";
import { LineString, Point } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import React, { useImperativeHandle, useState } from "react";
import { useEffect, useRef } from "react";
import { GetRoutePointResponse, GetStopPointResponse } from "src/api/UIClient";
import * as proj from "ol/proj";
import Fill from "ol/style/Fill";
import Circle from "ol/style/Circle";
import GeomCircle from "ol/geom/Circle";

export type MapProps = {};

export type MapHandle = {
  setRoutePoints: (route: GetRoutePointResponse[]) => void;

  setStopPoints: (stops: GetStopPointResponse[]) => void;
  showStopPoint: (index: number) => void;
};

const MapComponent = React.forwardRef<MapHandle, MapProps>(
  (props: MapProps, ref: React.ForwardedRef<MapHandle>) => {
    const {} = props;

    const mapDivRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map>();
    const vectorSourceRef = useRef<VectorSource>();

    useEffect(() => {
      vectorSourceRef.current = new VectorSource();

      mapRef.current = new Map({
        target: mapDivRef.current!,
        view: new View({
          center: [0, 0],
          zoom: 1,
        }),
        layers: [
          new TileLayer({
            source: new OSM(),
            maxZoom: 22,
          }),
          new VectorLayer({
            source: vectorSourceRef.current,
            style: new Style({
              stroke: new Stroke({
                color: "#0000FF",
                width: 2,
                lineCap: "square",
                lineJoin: "round",
              }),
              image: new Circle({
                radius: 2,
                fill: new Fill({
                  color: "#9090FF",
                }),
              }),
              fill: new Fill({
                color: "#0000FF",
              }),
            }),
          }),
        ],
        controls: [],
        overlays: [],
      });

      mapRef.current.getViewport().oncontextmenu = () => false; // Disable right click on the map

      const observer = new ResizeObserver(() => {
        mapRef.current!.updateSize();
      });
      observer.observe(mapDivRef.current as Element);

      return () => {
        observer.disconnect();
        mapRef.current?.setTarget(undefined);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      setRoutePoints: (points: GetRoutePointResponse[]) => {
        const source = vectorSourceRef.current!;
        source.clear();

        if (points.length > 0) {
          source.addFeature(
            new Feature({
              geometry: new LineString(
                points.map((it) =>
                  proj.transform(
                    [it.longitude!, it.latitude!],
                    "EPSG:4326",
                    "EPSG:3857"
                  )
                )
              ),
            })
          );

          const view = mapRef.current?.getView();
          view?.fit(source.getExtent(), {
            size: mapRef?.current?.getSize(),
            padding: [40, 40, 40, 40],
            duration: 250,
          });
        }
      },

      setStopPoints: (stops: GetStopPointResponse[]) => {
        const source = vectorSourceRef.current!;
        source.clear();

        source.addFeatures(
          stops.map((it) => {
            return new Feature({
              geometry: new GeomCircle(
                proj.transform(
                  [it.longitude!, it.latitude!],
                  "EPSG:4326",
                  "EPSG:3857"
                ),
                16
              ),
            });
          })
        );

        const view = mapRef.current?.getView();
        view?.fit(source.getExtent(), {
          size: mapRef?.current?.getSize(),
          padding: [40, 40, 40, 40],
          duration: 250,
        });
      },

      showStopPoint: (index: number) => {
        const source = vectorSourceRef.current!;

        const feature = source.getFeatures().at(index);
        if (!feature) {
          return;
        }

        const extent = feature?.getGeometry()?.getExtent();

        const view = mapRef.current?.getView();
        view?.fit(extent!, {
          size: mapRef?.current?.getSize(),
          padding: [40, 40, 40, 40],
          duration: 250,
          maxZoom: 18,
        });
      }
    }));

    return (
      <div style={{ width: "100%", height: "100%" }} ref={mapDivRef}></div>
    );
  }
);

export default MapComponent;
