import React, { useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

export type ConfirmDialogOptions = {
  title: string
  content: string
  acceptLabel: string
  declineLabel: string
  accept?: () => void;
  decline?: () => void;
}

export type ConfirmDialogRef = {
  open: (options: ConfirmDialogOptions) => void;
}

const SkipDialog = React.forwardRef<ConfirmDialogRef, {}>((props, ref) => {
  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<ConfirmDialogOptions | null>(null);

  const handleClose = () => {
    if (data?.decline) {
      data?.decline();
    }
    setOpen(false);
  }

  const handleAccept = () => {
    if (data?.accept) {
      data.accept();
    }
    setOpen(false);
  }

  useImperativeHandle(ref, () => ({
    open: opts => {
      setData(opts);
      setOpen(true);
    }
  }));

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      TransitionProps={{
        onExited: () => {
          setData(null);
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">{data?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {data?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog-actions-dense">
        <Button onClick={handleClose}>{data?.declineLabel}</Button>
        <Button onClick={handleAccept}>{data?.acceptLabel}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default SkipDialog;
