import React, { useImperativeHandle, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { CreateVehicleRequest, GetVehicleResponse, UpdateVehicleRequest } from "src/api/UIClient";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "src/hooks/useApi";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";
import CustomTextField from "src/@core/components/mui/text-field";
import { InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";

const defaultData: GetVehicleResponse = {
};

const createSchema = (t: any) =>
  yup.object().shape({
    registrationNumber: yup
      .string()
      .required()
      .label(t("edit.fields.registrationNumber")),
  });

type FormData = {
  registrationNumber: string
}

export type VehicleEditDialogProps = {
  refreshList: () => void;
}

export type VehicleEditDialogRef = {
  open: (vehicle: GetVehicleResponse | null) => void;
}

const VehicleEditDialog = React.forwardRef<VehicleEditDialogRef, VehicleEditDialogProps>((props, ref) => {
  const { refreshList } = props;

  const { t } = useTranslation("vehicles");
  const toast = useToast();

  const [open, setOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string | null>(null);
  const [editName, setEditName] = useState<string | null>(null);

  const { loading: saving, call } = useApi<any>();

  const handleClose = () => {
    setOpen(false);
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      registrationNumber: "",
    } as FormData,
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  useImperativeHandle(ref, () => ({
    open: vehicle => {
      setEditId(vehicle?.id ?? null);
      setEditName(vehicle?.registrationNumber ?? null);
      setOpen(true);

      const data = vehicle ?? defaultData;
      setValue("registrationNumber", data.registrationNumber!);
    }
  }));

  const onSubmit = async (data: FormData) => {
    if (editId == null) {
      const req: CreateVehicleRequest = {
        registrationNumber: data.registrationNumber!,
      };

      const { error: createError } = await call(() =>
        API.vehicleCreate(req)
      );
      if (createError) {
        toast("error", t("common:oops"));
        console.error(createError);
        return;
      }
    } else {
      const req: UpdateVehicleRequest = {
        registrationNumber: data.registrationNumber!,
      }

      const { error: createError } = await call(() =>
        API.vehicleUpdate(editId!, req)
      );
      if (createError) {
        toast("error", t("common:oops"));
        console.error(createError);
        return;
      }
    }

    setOpen(false);
    refreshList();
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
         <DialogTitle id="alert-dialog-title">{ editId == null ? t("edit.titleAdd") : t("edit.titleEdit", { name: editName }) }</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Controller
              name="registrationNumber"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomTextField
                  fullWidth
                  value={value}
                  onChange={onChange}
                  label={t("edit.fields.registrationNumber")}
                  placeholder={t("edit.fields.registrationNumber")}
                  error={Boolean(errors.registrationNumber)}
                  {...(errors.registrationNumber && {
                    helperText: errors.registrationNumber.message,
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon icon="mdi:bus" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions-dense">
          <Button type="button" onClick={handleClose}>{ t("edit.actionCancel") }</Button>
          <Button type="submit" disabled={saving}>{ t("edit.actionSave") }</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default VehicleEditDialog;
