import { InputAdornment, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/@core/components/mui/text-field";
import { BaseStepProps } from "../types";
import StepButtonBar from "../components/StepButtonBar";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetOrganisationResponse, OnboardingDTO } from "src/api/UIClient";
import { Icon } from "@iconify/react";
import useApi from "src/hooks/useApi";
import { useEffect } from "react";
import { API } from "src/api";
import { useToast } from "src/context/ToastContext";

const createSchema = (t: any) =>
  yup.object().shape({
    authorityId: yup.string().required().label(t("selectPto.authority")),
  });

type StepSelectPTOProps = BaseStepProps;

const StepSelectPTO = (props: StepSelectPTOProps) => {
  const { onChangeStep, onSkip } = props;

  const toast = useToast();

  const { t } = useTranslation("onboarding");
  const {
    loading: loadingOperators,
    data: operators,
    call: callLoadOrganisations,
  } = useApi<GetOrganisationResponse[]>();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({
    defaultValues: {
      authorityId: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = async (data: Partial<OnboardingDTO>) => onChangeStep("create-employee", data);

  const fetchOrganisations = async () => {
    const { data: organisationData, error } = await callLoadOrganisations(() => API.organisationGetAll());
    if (error) {
      toast("error", t("common:oops"));
      console.error(error)
      return
    }

    if (organisationData!.length > 0) {
      setValue("authorityId", organisationData![0].id!)
    }
  }

  useEffect(() => {
    fetchOrganisations()
  }, []);

  return (
    <>
      <Box>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="authorityId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomTextField
                select
                fullWidth
                id="custom-select"
                sx={{ mb: 2 }}
                value={value}
                onChange={onChange}
                error={Boolean(errors.authorityId)}
                {...(errors.authorityId && {
                  helperText: errors.authorityId.message,
                })}
                InputProps={{
                  startAdornment: loadingOperators ? (
                    <InputAdornment position="start">
                      <Icon icon="line-md:loading-loop" />
                    </InputAdornment>
                  ) : null,
                }}
              >
                {operators?.map((value, index) => (
                  <MenuItem key={index} value={value.id!}>
                    {value.name}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
          <StepButtonBar type="submit" disabled={isSubmitting} onSkip={onSkip} />
        </form>
      </Box>
    </>
  );
};

export default StepSelectPTO;
