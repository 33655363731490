import { Icon } from "@iconify/react";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/@core/components/mui/text-field";
import { BaseStepProps } from "../types";
import StepButtonBar from "../components/StepButtonBar";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import {
  ImportRequestDTO,
  ImportRequestDTOType,
  OnboardingDTO,
  OnboardingDTOOrganisationType,
} from "src/api/UIClient";
import { callApi } from "src/hooks/useApi";
import { API } from "src/api";
import LinearProgressWithLabel from "src/components/LinearProgressWithLabel";
import useSignalRMessage from "src/hooks/useSignalRMessage";
import { StoreImportMessage } from "src/api/signalRMessageTypes";
import useFunctionRef from "src/hooks/useFunctionRef";
import { useToast } from "src/context/ToastContext";

const createSchema = (t: any) =>
  yup.object().shape({
    type: yup
      .string()
      .required()
      .oneOf(Object.values(ImportRequestDTOType))
      .label(t("loadStore.fields.type")),
    url: yup
      .string()
      .url()
      .when("type", ([type], schema) =>
        type !== ImportRequestDTOType.NETEX ? schema.required() : schema
      )
      .label(t("loadStore.fields.url")),
    zipFile: yup
      .string()
      .when("type", ([type], schema) =>
        type === ImportRequestDTOType.NETEX ? schema.required() : schema
      )
      .label(t("loadStore.fields.zipFile")),
    stopsFile: yup
      .string()
      .when("type", ([type], schema) =>
        type === ImportRequestDTOType.NETEX ? schema.required() : schema
      )
      .label(t("loadStore.fields.stopsFile")),
  });

type StepLoadStoreProps = BaseStepProps;

type FormData = {
  type?: ImportRequestDTOType;
  url?: string;
  zipFile?: string;
  stopsFile?: string;
};

const StepLoadStore = (props: StepLoadStoreProps) => {
  const { onChangeStep, onSubmitData, onSkip, data } = props;

  const toast = useToast();
  const { t } = useTranslation("onboarding");

  const [storeLoading, setStoreLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [storeLoadError, setStoreLoadError] = useState<string | null>(null);
  const [storeLoadProgress, setStoreLoadProgress] = useState<number>(0);

  const importProgressCallback = useFunctionRef(
    (msg: StoreImportMessage) => {
      if (msg.completed) {
        setDataLoaded(true);
        setStoreLoading(false);
      } else if (msg.error) {
        setStoreLoadError(msg.error);
        setStoreLoading(false);
      } else {
        setStoreLoadProgress(msg.progress);
      }
    },
    [storeLoading, dataLoaded, storeLoadError, storeLoadProgress]
  );

  useSignalRMessage("importProgress", (msg: StoreImportMessage) =>
    importProgressCallback(msg)
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      type: data.dataSource?.type ?? ImportRequestDTOType.GTFS,
      url: data.dataSource?.gtfs?.url ?? data.dataSource?.txc?.url ?? "",
      zipFile: data.dataSource?.netex?.zipFile ?? "/import/netex.zip",
      stopsFile: data.dataSource?.netex?.stopsFile ?? "/import/stops.xml",
    } as FormData,
    mode: "onSubmit",
    resolver: yupResolver(createSchema(t)),
  });

  const onSubmit = async (data: FormData) => {
    const importRequest: ImportRequestDTO = {
      type: data.type!,
      ...(data.type === ImportRequestDTOType.GTFS
        ? {
            gtfs: {
              url: data.url!,
            },
          }
        : null),
      ...(data.type === ImportRequestDTOType.TXC
        ? {
            txc: {
              url: data.url!,
            },
          }
        : null),
      ...(data.type === ImportRequestDTOType.NETEX
        ? {
            netex: {
              zipFile: data.zipFile,
              stopsFile: data.stopsFile,
            },
          }
        : null),
    };

    const change: Partial<OnboardingDTO> = {
      dataSource: importRequest,
    };

    onSubmitData(change);

    setDataLoaded(false);

    const { error } = await callApi(() => API.onboardingImport(importRequest));
    if (error) {
      toast("error", t("common:oops"));
      console.error(error);
      setStoreLoadError(error?.message ?? "Error");
      setStoreLoading(false);
    } else {
      setStoreLoadError(null);
      setStoreLoading(true);
    }
  };

  const onNextStep = () =>
    onChangeStep(
      data.organisationType === OnboardingDTOOrganisationType.PTO
        ? "select-pto"
        : "create-employee",
      null
    );

  const importType = watch("type");

  return (
    <Box sx={{ flex: "1", display: "flex", flexDirection: "column" }}>
      {dataLoaded ? (
        <>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Icon
                icon="line-md:circle-to-confirm-circle-transition"
                color="#00aa00"
                fontSize="6rem"
                filter="drop-shadow(2px 5px 2px rgb(0 0 0 / 0.2))"
              />
            </Box>
            <Typography sx={{ mb: 3 }}>{t("loadStore.dataLoaded")}</Typography>
          </Box>
          <StepButtonBar onNext={onNextStep} onSkip={onSkip} />
        </>
      ) : storeLoading ? (
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 4,
              }}
            >
              <Icon
                icon="line-md:downloading-loop"
                color="#00aa00"
                fontSize="6rem"
                filter="drop-shadow(2px 5px 2px rgb(0 0 0 / 0.2))"
              />
            </Box>
            <Typography sx={{ mb: 3, textAlign: "center" }}>
              {t("loadStore.loadingInProgress")}
            </Typography>
          </Box>
          <LinearProgressWithLabel value={storeLoadProgress} />
        </Box>
      ) : storeLoadError ? (
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              icon="line-md:close-circle"
              color="#ff0000"
              fontSize="6rem"
              filter="drop-shadow(2px 5px 2px rgb(0 0 0 / 0.2))"
            />
            <Typography sx={{ mt: 4 }}>{storeLoadError}</Typography>
          </Box>
        </Box>
      ) : (
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ flex: "1", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flex: "1", display: "flex", justifyContent: "center" }}>
            <Box sx={{ flex: "1", maxWidth: 400 }}>
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CustomTextField
                    select
                    fullWidth
                    label={t("loadStore.dataSourceTypeLabel")}
                    id="custom-select"
                    sx={{ mb: 2 }}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.type)}
                    {...(errors.type && {
                      helperText: errors.type.message,
                    })}
                  >
                    {Object.values(ImportRequestDTOType).map((value, index) => (
                      <MenuItem key={index} value={value}>
                        {t(`loadStore.dataSourceType.${value}`)}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />

              {importType !== ImportRequestDTOType.NETEX && (
                <Controller
                  name="url"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomTextField
                      fullWidth
                      placeholder="http://example.com/data.zip"
                      label={t("loadStore.url")}
                      value={value}
                      onChange={onChange}
                      error={Boolean(errors.url)}
                      {...(errors.url && {
                        helperText: errors.url.message,
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon icon="bx:data" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}

              {importType === ImportRequestDTOType.NETEX && (
                <>
                  <Controller
                    name="zipFile"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomTextField
                        fullWidth
                        sx={{ mb: 2 }}
                        placeholder="/import/netex.zip"
                        label={t("loadStore.zip")}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.zipFile)}
                        {...(errors.zipFile && {
                          helperText: errors.zipFile.message,
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon icon="bx:data" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  <Controller
                    name="stopsFile"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomTextField
                        fullWidth
                        placeholder="/import/stops.xml"
                        label={t("loadStore.stops")}
                        value={value}
                        onChange={onChange}
                        error={Boolean(errors.stopsFile)}
                        {...(errors.stopsFile && {
                          helperText: errors.stopsFile.message,
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon icon="bx:data" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </>
              )}
            </Box>
          </Box>
          <StepButtonBar
            text="loadStore.importAction"
            type="submit"
            disabled={isSubmitting}
            onSkip={onSkip}
          />
        </form>
      )}
    </Box>
  );
};

export default StepLoadStore;
