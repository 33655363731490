import React, { createContext, useEffect, useState } from "react"
import FallbackSpinner from "src/@core/components/spinner"
import { API } from "src/api"
import { UserResponse } from "src/api/UIClient"
import { useToast } from "./ToastContext"
import { useTranslation } from "react-i18next"

export type AuthUser = UserResponse

export interface AuthContextType {
    user: AuthUser | null

    login: (user: AuthUser) => void
    logout: () => void

    setConfig: (key: string, value: any) => void
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    login: () => {},
    logout: () => {},
    setConfig: () => {},
})

export const AuthContextProvider = (props: React.PropsWithChildren<{}>) => {
    const toast = useToast();
    const { t } = useTranslation("common");

    const [user, setUser] = useState<AuthUser | null>(null)
    const [init, setInit] = useState<boolean>(false)

    useEffect(() => {
        API.userWhoami()
        .then(it => {
            setUser(it)
            setInit(true)
        })
        .catch(_ => {
            // We don't care about the error here, since this is used
            // to check if there's a valid session
            setInit(true)
        })
    }, [])

    const value = {
        user,

        login: (user: AuthUser) => setUser(user),
        logout: () => {
            API.authLogout().then(it => {
                setUser(null)
            }).catch(err => {
                toast("error", t("oops"));
                console.error(err)
                setUser(null)
            })
        },
        setConfig: (key: string, value: any) => {
            setUser({
                ...user,
                config: {
                    ...user!.config,
                    [key]: value,
                }
            })
        }
    }

    return <AuthContext.Provider value={value}>
        {
            init ? <>{ props.children }</> : <FallbackSpinner />
        }
    </AuthContext.Provider>
}
