import { useTheme } from "@mui/system"
import { createContext, useContext } from "react"
import toast, { ToastOptions, Toaster } from "react-hot-toast"

type ToastLevel = "success" | "error"

export type ToastFunction = (level: ToastLevel, message: string) => void

export type ToastContextType = {
  show: ToastFunction
}

export const ToastContext = createContext<ToastContextType>({
  show: () => {},
})

export const ToastProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;

  const theme = useTheme();

  const value: ToastContextType = {
    show: (level, message) => {
      const options: ToastOptions = {
        style: {
          background: theme.palette.mode === "dark" ? '#434A68' : "#ffffff",
          color: theme.palette.mode === "dark" ? "#EDEDEF" : "#363636",
        }
      }

      if (level === "success") {
        toast(message, options)
      } else if (level === "error") {
        toast(message, options);
      } else {
        console.error(`Invalid toast level: '${level}'`)
      }
    }
  }

  return <ToastContext.Provider value={value}>
    <Toaster position="top-right" />
    { children }
  </ToastContext.Provider>
}

export const useToast = (): ToastFunction => {
  const context = useContext(ToastContext)

  return context.show;
}
