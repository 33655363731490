import { useContext, useEffect } from "react";
import { SignalRContext } from "src/context/SignalRContext";

type MessageHandler = (data: any) => void;

const useSignalRMessage = (messageType: string, handler: MessageHandler) => {
  const signalRContext = useContext(SignalRContext);

  useEffect(() => {
    if (signalRContext.connection) {
      signalRContext.connection.on(messageType, handler);
    }

    return () => {
      if (signalRContext.connection) {
        signalRContext.connection.off(messageType, handler);
      }
    };
  }, [signalRContext]);
};

export default useSignalRMessage;
