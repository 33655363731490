import { Box, styled } from "@mui/system";
import { ViewMode } from "./ListCardView";
import { IconButton, TablePagination } from "@mui/material";
import { Icon } from "@iconify/react";
import {
  GridFooterContainer,
  gridPaginationSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid";
import { LIST_CARD_PAGESIZE } from "./ListCardPaginationContext";
import React from "react";

export type CardViewFooterProps = {
  viewMode: ViewMode;
  toggleViewMode: () => void;

  itemsCount: number;
  page: number;
  rowsPerPageOptions: number[];
  rowsPerPage: number;

  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
};

const CardViewFooterWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: "52px",
  borderTop: "1px solid rgba(47, 43, 61, 0.16)",
});

export const CardViewFooter = (props: CardViewFooterProps) => {
  const {
    viewMode,
    toggleViewMode,
    itemsCount,
    page,
    rowsPerPageOptions,
    rowsPerPage,
    onPageChange,
    onPageSizeChange,
  } = props;

  return (
    <CardViewFooterWrapper>
      <Box sx={{ ml: 3, cursor: "pointer" }}>
        <IconButton onClick={toggleViewMode}>
          <Icon icon={viewMode === "list" ? "tabler:list" : "tabler:table"} />
        </IconButton>
      </Box>
      <TablePagination
        component="div"
        count={itemsCount}
        page={page}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        onPageChange={(_, page) => onPageChange(page)}
        onRowsPerPageChange={(event) => {
          const pageSize = Number(event.target.value);
          onPageSizeChange(pageSize);
        }}
      />
    </CardViewFooterWrapper>
  );
};

export type DataTableFooterComponentProps = {
  viewMode: ViewMode;
  toggleViewMode: () => void;
};

export function DataTableFooterComponent(props: DataTableFooterComponentProps) {
  const { viewMode, toggleViewMode } = props;

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const paginationState = useGridSelector(apiRef, gridPaginationSelector);
  const { pageSize, page, rowCount } = paginationState;

  const pageSizeOptions = (rootProps.rowsPerPageOptions ??
    LIST_CARD_PAGESIZE) as number[];

  const handlePageSizeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const pageSize = Number(event.target.value);
      apiRef.current.setPageSize(pageSize);
    },
    [apiRef]
  );

  const handlePageChange = React.useCallback<any>(
    (_: any, page: number) => {
      apiRef.current.setPage(page);
    },
    [apiRef]
  );

  return (
    <GridFooterContainer>
      <Box sx={{ ml: 3, cursor: "pointer" }}>
        <IconButton onClick={toggleViewMode}>
          <Icon icon={viewMode === "list" ? "tabler:list" : "tabler:table"} />
        </IconButton>
      </Box>
      <TablePagination
        component="div"
        count={rowCount}
        page={page}
        rowsPerPageOptions={pageSizeOptions}
        rowsPerPage={pageSize}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </GridFooterContainer>
  );
}
