import { useState } from "react";

export interface ApiCall<TData, TError = any> {
  data: TData | null
  error: TError | null
  status: number | null
}

// TODO: Define API call helpers better.
export const callApi = async <TData, TError = any>(api: () => Promise<TData>): Promise<ApiCall<TData, TError>> => {
  try {
    const res = await api()
    return {
      data: res,
      error: null,
      status: null,
    }
  } catch (error: any) {
    return {
      data: null,
      error: error as TError,
      status: error.status as number,
    }
  }
}

export interface UseApiHook<TData, TError = any> {
  loading: boolean;
  data: TData | null;
  error: TError | null;
  call: (api: () => Promise<TData>) => Promise<ApiCall<TData, TError>>;
}

const useApi = <TData, TError = any>(): UseApiHook<TData, TError> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TData | null>(null);
  const [error, setError] = useState<TError | null>(null);

  const call = async (api: () => Promise<TData>): Promise<ApiCall<TData, TError>> => {
    setLoading(true);

    try {
      const res = await api();

      setData(res)
      setLoading(false)

      return {
        data: res,
        error: null,
        status: null,
      }
    } catch (err: any) {
      setError(err as TError)
      setLoading(false)

      console.error(err)

      return {
        data: null,
        error: err as TError,
        status: err.status as number,
      }
    }
  };

  return {
    loading,
    data,
    error,
    call,
  };
};

export default useApi;
