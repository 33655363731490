// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
  return [
    {
      title: 'navmenu:dashboard',
      icon: 'tabler:smart-home',
      path: "/",
    },
    {
      title: 'navmenu:vehicles',
      icon: 'tabler:bus',
      path: "/vehicles",
    },
    {
      title: 'navmenu:employees',
      icon: 'tabler:user',
      path: "/employees",
    },
    {
      title: 'navmenu:network',
      icon: 'tabler:route',
      path: "/network",
    }
  ]
}

export default navigation
