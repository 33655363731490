import common from "./en/common.json"
import navmenu from "./en/navmenu.json"
import login from "./en/login.json"
import onboarding from "./en/onboarding.json"
import vehicles from "./en/vehicles.json"
import network from "./en/network.json"
import employees from "./en/employees.json"
import devices from "./en/devices.json"

export default {
    common,
    navmenu,
    login,
    onboarding,
    vehicles,
    network,
    employees,
    devices,
}
